<script>
import {UPopover, UDialog, UInput, UField} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import {enviaTeste, upgradeCampanha} from '@/domain/marketing/services'
// import {donwloadFile} from "@/utils/downloadFile"

export default {
  name: 'AcaoMkt',
  inject: ['container'],
  components: {MenuOptionsItem, UPopover, UDialog, UInput, UField},
  computed: {
    campanha () {
      return this.container.campanha
    }
  },
  data() {
    return {
      testeDialog: false,
      testeData: {
        destinatario: null
      }
    }
  },
  methods: {
    editar () {
      this.$router.push({name: 'marketing.criar-campanha.canal', params: {
        id: this.campanha.id,
        canal: this.campanha.canal,
        action: 'destinatarios',
      }})
    },
    testar () {
      this.testeDialog = true
      setTimeout(() => {
        this.$refs.testeEmailInput.focus()
      }, 500)
    },
    atualizarInscritos () {
      this.$uloc.loading.show()
      upgradeCampanha(this.campanha.id, {
        status: 0
      })
          .then(response => {
            this.$uloc.loading.hide()
            this.container.load()
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    enviarTeste () {
      if (!this.testeData.destinatario || !this.testeData.destinatario.length) {
        this.$uloc.dialog({title: 'Atenção', message: 'Informe um e-mail para enviar o teste'})
        // this.$refs.testeEmailInput.focus()
        return
      }
      this.$uloc.loading.show()
      enviaTeste(this.campanha.id, {destinatario: this.testeData.destinatario})
          .then(response => {
            this.$uloc.loading.hide()
            this.testeDialog = false
            this.dg('Teste enviado com sucesso!')
          })
          .catch(error => {
            this.alertApiError(error)
            this.$uloc.loading.hide()
          })
    }
  }
}
</script>

<template>
  <div>
    <e-btn align="left" label="Ações">
      <span class="m-l-md"></span> <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7" style="font-size: 10px !important;"/>
      <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
        <div class="menu_ menu-btn-leilao">
          <ul>
            <menu-options-item @click="editar" label="Editar Campanha" close  />
            <menu-options-item @click="atualizarInscritos" label="Atualizar Inscritos" close  />
            <menu-options-item @click="testar" label="Enviar um teste" close  />
          </ul>
          <!--        <ul>
                    <menu-options-item label="Imprimir" close  />
                  </ul>--> <!-- @TODO -->
          <slot />
        </div>
      </u-popover>
    </e-btn>
    <u-dialog
        v-model="testeDialog"
        @cancel="testeDialog = false"
    >
      <span slot="title">Enviar teste de campanha</span>

      <!-- @TODO: Implementar canais -->

      <div slot="body">
        <u-field
            icon="mail"
            label="Informe o e-mail que deseja enviar um teste:"
            orientation="vertical"
        >
          <u-input required ref="testeEmailInput" autofocus v-model="testeData.destinatario" />
        </u-field>
      </div>

      <template slot="buttons" slot-scope="props">
        <u-btn flat label="Cancelar" @click="props.cancel" />
        <u-btn color="primary" label="Enviar teste" @click="enviarTeste" />
      </template>
    </u-dialog>
  </div>
</template>
