<script>
    export default {
      name: 'task-menu-options-item',
      props: {
        label: String,
        shortcut: String,
        close: {
          type: Boolean,
          default: false
        },
        disable: {
          type: Boolean,
          default: false
        }
      },
      data () {
        return {}
      },
      computed: {},
      mounted () {
      },
      destroyed () {
      },
      methods: {
        closeMenu (ev) {
          let vm = this
          while ((vm = vm.$parent)) {
            const name = vm.$options.name
            if (name === 'UPopover' || name === 'UModal') {
              vm.hide(ev)
              break
            }
          }
        },
        click (evt) {
          if (this.disable) return
          if (this.close) {
            this.closeMenu(evt)
          }
          this.$emit('click', evt)
        }
      },
      components: {}
    }
</script>

<template>
    <li :class="{disabled: disable}">
        <a @click="click" class="menuItem">
            <span class="menuLabel">
                <div class="flex col-grow no-wrap justify-between">
                    <span class="menu-label-content">{{label}}</span>
                    <span class="shortcut">{{shortcut}}</span>
                </div>
            </span>
        </a>
    </li>
</template>
